import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HandledError } from 'src/app/interfaces/handled-error.interface';

@Injectable({
  providedIn: 'root',
})
export class ErrorToastService {
  private readonly toastInfo$ = new Subject<HandledError & { showToast: boolean }>();
  private toastInfo!: HandledError & { showToast: boolean };

  constructor() {}

  set ToastInfo(newErrorInfo: HandledError) {
    if (this.toastInfo && this.toastInfo.showToast) {
      this.toastInfo.showToast = false;
      this.toastInfo$.next(this.toastInfo);
    }
    this.toastInfo = { ...newErrorInfo, showToast: true };
    this.toastInfo$.next(this.toastInfo);
  }

  get ToastInformationChanges(): Observable<HandledError & { showToast: boolean }> {
    return this.toastInfo$.asObservable();
  }

  hideToast(): void {
    if (this.toastInfo && this.toastInfo.showToast) {
      this.toastInfo.showToast = false;
      this.toastInfo$.next(this.toastInfo);
    }
  }
}
