export const enum WalletState {
  connected = 'CONNECTED',
  error = 'ERROR',
  disconected = 'DISCONECTED',
}

export interface StoredConnection {
  status: WalletState;
  walletAddress: string;
}

export const LS_KEY = 'wallet-state';
