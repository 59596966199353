<video
  class="video-layer"
  [ngClass]="{
    'd-none': currentLayer.name !== 'connectWalletBg',
    'd-block': currentLayer.name === 'connectWalletBg'
  }"
  [src]="videos.connectWalletBg"
  autoplay
  loop
  [muted]="'muted'"></video>
<video
  class="video-layer"
  [ngClass]="{
    'd-none': currentLayer.name !== 'revealTrigger',
    'd-block': currentLayer.name === 'revealTrigger'
  }"
  [src]="videos.revealTrigger"
  [muted]="'muted'"
  (ended)="videoEnded()"
  #revealTrigger></video>
<video
  class="video-layer"
  [ngClass]="{
    'd-none': currentLayer.name !== 'revealAnimation',
    'd-block': currentLayer.name === 'revealAnimation'
  }"
  [src]="videos.revealAnimation"
  autoplay
  loop
  [muted]="'muted'"></video>
