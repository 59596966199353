import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseComponent } from '../../base/component.base';
import { VideoLayer } from '../../services/video-layer/animation.type';
import { LayerService } from '../../services/video-layer/layer.service';

@Component({
  selector: 'app-layer',
  templateUrl: './layer.component.html',
  styleUrls: ['./layer.component.scss'],
})
export class LayerComponent extends BaseComponent implements OnInit {
  @ViewChild('revealTrigger', { static: true }) revealTrigger!: ElementRef<HTMLVideoElement>;

  readonly videos = {
    connectWalletBg:
      environment.srcUrl + '/trantorian-nft-reveal/videos/video1-loop-placeholder.mp4',
    revealTrigger: environment.srcUrl + '/trantorian-nft-reveal/videos/video2-placeholder.mp4',
    revealAnimation:
      environment.srcUrl + '/trantorian-nft-reveal/videos/video3-loop-placeholder.mp4',
  };
  currentLayer: VideoLayer;

  constructor(private readonly layerService: LayerService) {
    super();
    this.currentLayer = this.layerService.CurrentLayer;
  }

  ngOnInit(): void {
    this.listenLayerChanges();
  }

  private listenLayerChanges(): void {
    this.unsubscribeOnDestroy(
      this.layerService.layerChanges.subscribe((newLayer) => {
        this.currentLayer = newLayer;
        if (newLayer.name === 'revealTrigger' && newLayer.status === 'started') {
          this.revealTrigger.nativeElement.currentTime = 0;
          this.revealTrigger.nativeElement.play();
        }
      })
    );
  }

  videoEnded(): void {
    this.layerService.setCurrentLayer({
      name: 'revealAnimation',
      status: 'started',
    });
  }
}
