import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { VideoLayer } from './animation.type';

@Injectable({
  providedIn: 'root',
})
export class LayerService {
  layerStatus: 'initialized' | 'running';
  private readonly animation = new ReplaySubject<VideoLayer>(1);
  private currentLayer: VideoLayer = { name: 'connectWalletBg', status: 'started' };

  constructor() {
    this.layerStatus = 'initialized';
  }

  get layerChanges(): Observable<VideoLayer> {
    return this.animation.asObservable();
  }

  get CurrentLayer(): VideoLayer {
    return this.currentLayer;
  }

  setCurrentLayer(newLayer: VideoLayer): void {
    this.currentLayer = newLayer;
    this.layerStatus = 'running';
    this.animation.next(this.currentLayer);
  }
}
