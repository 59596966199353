import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../base/component.base';
import { HandledError } from '../../interfaces/handled-error.interface';
import { ErrorToastService } from './error-toast.service';

@Component({
  selector: 'app-error-toast',
  standalone: true,
  imports: [CommonModule, NgbToastModule],
  templateUrl: './error-toast.component.html',
  styleUrls: ['./error-toast.component.scss'],
})
export class ErrorToastComponent extends BaseComponent implements OnInit {
  errorLogs: HandledError = { errorCode: '', errorMessage: '', logs: '' };
  showToast: boolean;

  constructor(private readonly errorToast: ErrorToastService) {
    super();
    this.showToast = false;
  }

  ngOnInit(): void {
    this.listenToastInformationChanges();
  }

  private listenToastInformationChanges(): void {
    this.unsubscribeOnDestroy(
      this.errorToast.ToastInformationChanges.subscribe((newErrorInfo) => {
        this.errorLogs = newErrorInfo;
        this.showToast = newErrorInfo.showToast;
        if (!environment.production) console.table(newErrorInfo);
      })
    );
  }
}
