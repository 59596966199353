/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { LS_KEY, StoredConnection, WalletState } from '../guards/wallet-is-connected/wallet-states';
import { Account } from '../interfaces/account.interface';
import { HandledError } from '../interfaces/handled-error.interface';
import { IntegratedWallet } from './integrated-wallets/integrated-wallets';
import { MyAlgoService } from './integrated-wallets/my-algo.service';
import { PeraWalletService } from './integrated-wallets/pera-wallet.service';
import { WalletErrorCode, WalletErrorMessage } from './integrated-wallets/wallet-error';

@Injectable({
  providedIn: 'root',
})
export class WalletHandlerService {
  private account!: Account | null;

  constructor(private myAlgo: MyAlgoService, private readonly peraWallet: PeraWalletService) {}

  connectWalletByIntegrationSelected(wallet: IntegratedWallet): Observable<Account | HandledError> {
    if (!this[wallet]) return this.handleWalletNotSupportedException();
    return this.handleWalletConnection(this[wallet].connectWallet());
  }

  private handleWalletNotSupportedException(): Observable<Account | HandledError> {
    localStorage.setItem(LS_KEY, WalletState.error);
    return of({
      errorMessage: WalletErrorCode.connectionMethodNotSupported,
      logs: null,
    } as HandledError);
  }

  private handleWalletConnection(
    connectionAttempt: Observable<Account>
  ): Observable<Account | HandledError> {
    return connectionAttempt.pipe(
      map((response) => {
        this.saveAccountInfo(response);
        return response;
      }),
      catchError((error) =>
        of({
          errorCode: WalletErrorCode.errorTryingToConnect,
          errorMessage: error?.message || WalletErrorMessage.errorTryingToConnect,
          logs: error,
        } as unknown as HandledError)
      )
    );
  }

  private saveAccountInfo(account: Account): void {
    this.account = account;
    this.saveConnectionStatusInLocalStorage(WalletState.connected, account);
  }

  private saveConnectionStatusInLocalStorage(status: WalletState, account?: Account): void {
    localStorage.setItem(
      LS_KEY,
      JSON.stringify(<StoredConnection>{
        status,
        walletAddress: account?.walletAddress || '',
      })
    );
  }

  get Account() {
    return this.account;
  }
  set changeAccount(newAccount: Account | null) {
    this.account = newAccount;
  }
  getWalletAddressFromLocalStorage(): void {
    const lsKeyContent = localStorage.getItem(LS_KEY);
    const connectionStored = lsKeyContent ? (JSON.parse(lsKeyContent) as StoredConnection) : null;
    if (connectionStored && connectionStored.status === WalletState.connected) {
      this.account = {
        walletAddress: connectionStored.walletAddress,
      };
    } else {
      localStorage.removeItem(LS_KEY);
    }
  }
}
