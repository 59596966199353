import { environment } from '../../../environments/environment';

// Add Required Website Assets here
export const enum AssetId {
  connectWalletVideoBg = 'connectWalletVideoBg',
  trantorianLogo = 'trantorianLogo',
  avatarPlaceHolder = 'avatarPlaceHolder',
  revealTriggerVideo = 'revealTriggerVideo',
  revealAnimationVideo = 'revealAnimationVideo',
}

// Required Asset interface
export interface RequiredAsset {
  assetId: AssetId;
  type: 'image' | 'video';
  srcUrl: string;
}

// Add Required Assets by AssetId as objects that follos the above interface
export const _REQUIRED_ASSETS_: RequiredAsset[] = [
  {
    assetId: AssetId.connectWalletVideoBg,
    srcUrl: environment.srcUrl + '/trantorian-nft-reveal/videos/video1-loop-placeholder.mp4',
    type: 'video',
  },
  {
    assetId: AssetId.trantorianLogo,
    srcUrl: environment.srcUrl + '/trantorian-nft-reveal/images/trantorian-logo.png',
    type: 'image',
  },
  {
    assetId: AssetId.avatarPlaceHolder,
    srcUrl: environment.srcUrl + '/trantorian-nft-reveal/images/avatar-placeholder.png',
    type: 'image',
  },
  {
    assetId: AssetId.revealTriggerVideo,
    srcUrl: environment.srcUrl + '/trantorian-nft-reveal/videos/video2-placeholder.mp4',
    type: 'video',
  },
  {
    assetId: AssetId.revealAnimationVideo,
    srcUrl: environment.srcUrl + '/trantorian-nft-reveal/videos/video3-loop-placeholder.mp4',
    type: 'video',
  },
];

export const getAssetsToPreload = () =>
  _REQUIRED_ASSETS_.map((asset) => ({ [asset.assetId]: false }));
