import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LayerService } from '../../services/video-layer/layer.service';
import { _APP_ROUTES_ } from '../../utils/app-route-names.utils';
import { LS_KEY, StoredConnection, WalletState } from './wallet-states';

@Injectable({
  providedIn: 'root',
})
export class WalletIsConnectedGuard implements CanLoad {
  constructor(private readonly router: Router, private readonly layerService: LayerService) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.checkWalletConnection() && this.checkLayerAnimationsAreRunning()) return true;
    return this.router.createUrlTree([_APP_ROUTES_.connectWallet]);
  }

  private checkWalletConnection(): boolean {
    const walletConnected = localStorage.getItem(LS_KEY);
    return new Boolean(
      walletConnected?.length &&
        (JSON.parse(walletConnected) as StoredConnection).status === WalletState.connected
    ).valueOf();
  }

  private checkLayerAnimationsAreRunning(): boolean {
    return this.layerService.layerStatus === 'running';
  }
}
